import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b53393a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-main" }
const _hoisted_2 = { class: "c-emailThanks" }
const _hoisted_3 = { class: "c-emailThanks-title" }
const _hoisted_4 = { class: "c-emailThanks-text" }
const _hoisted_5 = { class: "u-marginBottomUI--xSmall" }
const _hoisted_6 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_powered_by_okendo = _resolveComponent("powered-by-okendo")!
  const _component_view_state = _resolveComponent("view-state")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.viewState === 'content')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_icon, {
              icon: "verified-tick",
              class: "c-emailThanks-image"
            }),
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('Thank you!')), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('Your review has been successfully verified')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gotoStore())),
              "aria-label": _ctx.$t('Store Website'),
              class: "c-button"
            }, _toDisplayString(_ctx.$t('Continue to Store')), 9, _hoisted_6)
          ]),
          _createVNode(_component_powered_by_okendo)
        ], 64))
      : _createCommentVNode("", true),
    _createVNode(_component_view_state, {
      onRetry: _ctx.loadVerification,
      "view-state": _ctx.viewState,
      errorCode: _ctx.errorCode
    }, null, 8, ["onRetry", "view-state", "errorCode"])
  ]))
}