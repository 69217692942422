
import { Options, Vue } from 'vue-class-component';

import { postAnalytics, postEmailVerification, postErrorAnalytics } from '@/utils/api';
import Icon from '@/shared-components/Icon.vue';
import PoweredByOkendo from '@/shared-components/PoweredByOkendo.vue';
import store from '@/store';
import ViewState from '@/shared-components/ViewState.vue';
import { ErrorCode } from '@/utils/errorUtils';

@Options({
    components: {
        Icon,
        ViewState,
        PoweredByOkendo
    },
    store
})
export default class EmailThanks extends Vue {
    viewState = 'loading';
    errorCode: ErrorCode = 'unknown';

    mounted(): void {
        postAnalytics({ eventName: 'page-open' });
        this.loadVerification();
    }

    async loadVerification(): Promise<void> {
        const { reviewId, verificationCode } = store.state.verification;
        this.viewState = 'loading';
        this.errorCode = 'unknown';

        if (!reviewId || !verificationCode) {
            postErrorAnalytics(`Invalid reviewId: ${reviewId} or verificationCode: ${verificationCode}`);
            this.errorCode = 'product-not-found';
            this.viewState = 'error';
            return;
        }

        try {
            await postEmailVerification(reviewId, verificationCode);
            postAnalytics({
                eventName: 'action-verified-review',
                label: 'reviewId',
                value: reviewId
            });

            this.viewState = 'content';
        }
        catch (error) {
            postErrorAnalytics(error);
            this.viewState = 'error';
        }
    }

    gotoStore(): void {
        window.location.href = store.state.subscriber.storeUrl;
    }
}

